<template>
  <component
    :is="event.external_url ? NuxtLink : 'div'"
    :to="event.external_url"
    rel="noopener noreferrer nofollow"
    target="_blank"
    class="link-escape group"
  >
    <div class="date pb-1 font-bold text-green-darkest">
      {{ formatDate(new Date(event.start_date), 'dd MMMM yyyy') }}
    </div>
    <div
      class="text-xl font-bold text-black lg:text-2xl lg:transition-transform lg:duration-300 lg:group-hover:translate-x-4"
    >
      {{ event.title }}
    </div>
  </component>
</template>

<script setup lang="ts">
import type {EventsQuery} from '~/graphql/graphql';
import {NuxtLink} from '#components';

defineProps<{
  event: EventsQuery['events'][number];
}>();

const formatDate = useLocaleDate();
</script>
